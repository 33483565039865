// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
// = require_tree .
import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'jquery'
import 'bootstrap'
import 'trix'
import 'moment'
import 'daterangepicker'
import 'datatables.net'
import 'datatables.net-dt'
import 'easy-autocomplete'
import 'channels'
import '@fortawesome/fontawesome-free/js/all'
import 'chartkick/chart.js'
import '../stylesheets/application'
import 'select2'
import 'select2/dist/css/select2.css'
import '@rails/actiontext'
import 'src/constants.js'
import 'src/common.js'
import 'src/leads.js'
import 'src/users.js'
import 'src/autocomplete.js'
import 'src/comments.js'
import 'controllers'
import '@nathanvda/cocoon'

global.$ = jQuery
global.toastr = require('toastr')

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '@client-side-validations/client-side-validations'

toastr.options = { closeButton: true, progressBar: true, timeOut: '3000' }
