$(document).on('turbolinks:load', () => {
  if ($('#users_form').length && !window.location.href.includes('new')){
    window.history.pushState({}, '', window.location.href + '/new')
  }

  $('#tech-button').on('click', () => {
    $('#modal-technology').val('')
    $('#modal-rating').val('')
    $('#modal-submit').attr('disabled', 'disabled')
  })

  $('#modal-technology, #modal-rating').select2({
    dropdownParent: $('#technologyModal')
  })

  $('#modal-technology, #modal-rating').on('change', () => {
    let rating = document.getElementById('modal-rating').value
    let technology = document.getElementById('modal-technology').value
    if (technology == '' || rating == '') {
      $('#modal-submit').attr('disabled', 'disabled')
    } else {
      $('#modal-submit').removeAttr('disabled')
    }
  })

  $('#user_technology').DataTable({
    colReorder: true,
    paging: false
  })

  let timer = null
  $('.users_search').on('keyup', (e) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      users_search(e)
    }, 500)
  })

  const users_search = (e) => {
    search_field = $('.users_search').val().length
    if (search_field > 2 || search_field == 0) {
      $.ajax({
        type: 'GET',
        url: '/users',
        data: $('#users-filter').serialize(),
        dataType: 'script'
      })
    }
  }

  $('#export_users').on('click', (e) => {
    e.preventDefault()
    window.location.href = '/users.csv?' + $('#users-filter').serialize()
  })

  navigate_to_user_assigned_leads = (user_id, assignee_type) => {
    let params = { search: { user_id: user_id, assignee_type: assignee_type } }
    window.location.replace(
      generateUrl('/leads?', params, $('#users-filter').serialize())
    )
  }

  shiftPage = (page_name, event) => {
    let tabcontent, tablinks
    tabcontent = $('.pageContent')
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    tablinks = $('.tablink')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].style.backgroundColor = ''
      tablinks[i].style.color = 'black'
    }
    document.getElementById(page_name).style.display = 'block'
    event.style.backgroundColor = '#555'
    event.style.color = 'white'
  }

  $('#defaultOpen').click()

  $('#users_form').on('change', () => {
    $('#users_save').removeAttr('disabled')
    $('.roles_errors').html('')
  })

  validate_roles_presence = () => {
    if (
      document.querySelector('input[name="user[role_ids][]"]:checked') == null
    ) {
      $('.roles_errors').html(
        '<span> \
          Please select Role \
        </span>'
      )
      return false
    }
    return true
  }

  set_user_lock = (user_id) => {
    $('.locked_user_id').val(user_id)
    $('#user_locked_from').val($(`#locked_from_${user_id}`).text())
    $('#user_locked_til').val($(`#locked_til_${user_id}`).text())
  }

  $('.lock_user_submit').on('click', function (e) {
    e.preventDefault()
    let id = $('.locked_user_id').val()
    $.ajax({
      type: 'post',
      url: `users/${id}/lock?`,
      data: $('#lock_user_form').serialize(),
      dataType: 'script'
    })
  })
})
