$(document).on('turbolinks:load', () => {
  $('.comment_box').scrollTop($('.comment_box').prop('scrollHeight'))
  $('.activity_box').scrollTop($('.activity_box').prop('scrollHeight'))

  $('#comment_form').on('keyup', () => {
    let users_list = document.querySelector('.tribute-container')
    if (users_list.style.top == 'auto') {
      let tribute_left = users_list.getBoundingClientRect().left
      $('.tribute-container').attr(
        'style',
        `top: ${
          $('body').height() - 400
        }px; position: absolute; left: ${tribute_left}px; bottom: 0; min-height: 150px`
      )
    }
  })
})
