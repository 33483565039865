$(document).on('turbolinks:load', () => {
  const alphanumers = /^[a-zA-Z0-9 ]+$/
  let options = {
    url: (phrase) => {
      if (alphanumers.test(phrase)) {
        return '/leads/suggestion.json?company_name=' + phrase
      }
    },
    getValue: 'company_name',
    requestDelay: 2000,
    list: {
      onSelectItemEvent: () => {
        $('#lead_company_name').css('border', '3px solid #FFD35C')
      },
      onLoadEvent: () => {
        $('#lead_company_name').css('border', '1px solid #cfcfcf')
      }
    }
  }
  $('*[data-behavior="autocomplete"]').easyAutocomplete(options)
})
