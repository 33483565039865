$(document).on('turbolinks:load', () => {
  let timer = null
  $('.search').on('keyup', (e) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      leads_search(e)
    }, 500)
  })

  const leads_search = (e) => {
    search_field = $('.search').val().length
    if (search_field > 2 || search_field == 0) {
      $.ajax({
        type: 'GET',
        url: '/leads',
        data: $('form').serialize(),
        dataType: 'script'
      })
    }
  }

  multipleSelectDropdownWithSearchBar = () => {
    let Utils = $.fn.select2.amd.require('select2/utils');
    let Dropdown = $.fn.select2.amd.require('select2/dropdown');
    let DropdownSearch = $.fn.select2.amd.require('select2/dropdown/search');
    let CloseOnSelect = $.fn.select2.amd.require('select2/dropdown/closeOnSelect');
    let AttachBody = $.fn.select2.amd.require('select2/dropdown/attachBody');
    let dropdownAdapter = Utils.Decorate(Utils.Decorate(Utils.Decorate(Dropdown, DropdownSearch), CloseOnSelect), AttachBody);
    return dropdownAdapter;
  }

  $('#lead-technologies-dropdown').select2({
    dropdownAdapter: multipleSelectDropdownWithSearchBar(),
    minimumResultsForSearch: 0,
  }).on('select2:opening select2:closing', (event) => {
    let searchfield = $(this).parent().find('.select2-search__field');
    searchfield.prop('disabled', true);
  });

  $('.phase-statuses').on('change', '#phase-dropdown', () => {
    let id = $('#phase-dropdown').val();
    $.ajax({
      url: "/phases/" + id,
      type: "GET"
    })
  });

  $('#status_dropdown').on('change', (e) => {
    if (
      e.target.value != '' &&
      $('#status_dropdown option:selected').text() != 'Prospect'
    ) {
      $('#status_reason').show()
    } else {
      $('#status_reason').hide()
    }
  })

$(function() {
  $(document).on("click", "button#export", function(e) {
    $('#export').html('Exporting..')
    $('#export').addClass('disabled')
    $.ajax({
      url: 'csvs',
      data: $('form').serialize(),
      dataType: "json",
    }).done(function(response, status) {
      if (status === "success" && response && response.jid) {
        let jobId = response.jid;
        window.intervalName = "job_" + jobId;
        toastr.success('Your CSV File is exporting in the background and will be downloaded shorty.')
        window[window.intervalName] = setInterval(function() {
          getExportJobStatus(jobId);
        }, 10000);
      }
    }).fail(function(error) {
      clearInterval(window[window.intervalName]);
      delete window[window.intervalName];
      toastr.error('Download Failed!');
    });
  });

  function getExportJobStatus(jobId) {
    $.ajax({
      url: "csvs/" + jobId,
      dataType: "json",
    }).done(function(response, status) {
      if (status === "success") {
        if (response.status === "completed") {
          setTimeout(function() {
            clearInterval(window[window.intervalName]);
            delete window[window.intervalName];
            $(location).attr("href", "csvs/new.csv?id=" + jobId);
            $('#export').html('Export CSV')
            $('#export').removeClass('disabled')
          }, 100);
        }
      }
    }).fail(function(error) {
      clearInterval(window[window.intervalName]);
      delete window[window.intervalName];
      toastr.error('Download Failed!');
    });
  }
});

  $('#lead_filters_form').on('change', () => {
    $('#lead_filter_submit').removeAttr('disabled')
    $('.filter_errors').html('')
  })

  validate_filter_form_on_phase_status_in_range = () => {
    if ($('#search_inactive').is(':checked')) {
      if (
        ($('#search_status_id').val() != '' ||
          $('#search_phase_id').val() != '') &&
        ($('#search_date_to').val() != '' || $('#search_date_from').val() != '')
      ) {
        return true
      } else {
        $('.filter_errors').html(
          "<span> \
                                  Please select 'Date to' or 'Date from' and 'Phase' or 'Status' \
                                  from filters to filter leads based on historical data\
                                </span>"
        )
        return false
      }
    }
    return true
  }

  $('#lead-technologies-dropdown').on('change', function () {
    let selectedValues = $(this).val()
    $('#engineer-technologies-dropdown').val(selectedValues).trigger('change')
  })

  $('#lead-form').on('change', () => {
    $('#lead_save').removeAttr('disabled')
    $('#lead_save_and_continue').removeAttr('disabled')
    $('.assignee_errors').html('')
    $('.lead_job_errors').html('')
    $('.lead_profile_errors').html('')
  })

  validate_lead_inputs = () => {
    let flag = true
    if (document.querySelector('input[name="lead[user_id]"]:checked') == null) {
      $('.assignee_errors').html(
        '<span> \
          Please select Lead Assignee \
        </span>'
      )
      flag = false
    }
    if ($('#job_select').val() == null) {
      $('.lead_job_errors').html(
        '<span> \
          must exist \
        </span>'
      )
      flag = false
    }
    if ($('#profile_select').val() == null) {
      $('.lead_profile_errors').html(
        '<span> \
          must exist \
        </span>'
      )
      flag = false
    }
    return flag
  }

  $(document).on('shown.bs.modal', '#phaseStatusModal', () => {
    reset_phase_status_due_date
    reset_status_due_date
  })

  $(document).on(
    'change',
    '#lead_phase_id',
    (reset_phase_status_due_date = () => {
      $('#lead_phase_due_date').val('')
      $('#lead_status_due_date').val('')
    })
  )

  $(document).on(
    'change',
    '#status_dropdown',
    (reset_status_due_date = () => {
      $('#lead_status_due_date').val('')
    })
  )

  with_over_due_leads = () => {
    let params = { search: { over_due_leads: 1 } }
    window.location.replace(
      generateUrl('/leads?', params, $('form').serialize())
    )
  }

  with_upcoming_leads = () => {
    let params = { search: { upcoming_leads: 1 } }
    window.location.replace(
      generateUrl('/leads?', params, $('form').serialize())
    )
  }

  openPage = (page_name, event) => {
    let tabcontent, tablinks
    tabcontent = $('.tabcontent')
    for (let i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none'
    }
    tablinks = $('.tablink')
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].style.backgroundColor = ''
      tablinks[i].style.color = 'black'
    }
    document.getElementById(page_name).style.display = 'block'
    event.style.backgroundColor = '#555'
    event.style.color = 'white'
  }

  setAttrLink = (tabName) => {
    let params = { tab: tabName }
    $(`.${tabName}`).attr(
      'href',
      generateUrl('/users?', params, $('#users-filter').serialize())
    )
  }

  $('.users-detail').on('click', () => {
    setAttrLink('users-detail')
  })

  $('.users-info').on('click', () => {
    setAttrLink('users-info')
  })

  $('.engineer-listing').on('click', () => {
    setAttrLink('engineer-listing')
  })

  $('#defaultOpen').click()

  $(document).on(
    'click',
    '#userTechStackUpdate',
    (userTechStackUpdate = (
      lead_id,
      clear = false,
      page,
      tabName = 'engineer-listing'
    ) => {
      if ($('#lead-form').length > 0) {
        tabName = 'engineer-listing'
      }
      form =
        tabName != 'engineer-listing'
          ? $('#users-filter').serialize()
          : $('#users-filters-in-lead-form').serialize()
      let params = {
        tech_stack_ids: $('.tech-select').val(),
        lead_id: lead_id,
        clear: clear,
        lead_page: $('#lead-form').length,
        page: page,
        tab: tabName
      }
      $.ajax({
        type: 'GET',
        url: generateUrl('/users?', params, form),
        dataType: 'script'
      })
    })
  )

  set_user_filter_form_offset = () => {
    if ($('.lead-submission').offset() != null) {
      $('.filter-form').offset({ top: $('.lead-submission').offset().top + 50 })
    }
  }
  set_user_filter_form_offset()
})
