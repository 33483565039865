$(document).on('turbolinks:load', () => {
  $('.select2').select2()

  $('form').on('cocoon:after-insert', () => {
    $('.member-field').select2()
  })

  $('#edit-delete-leaad').on('click', (event) => {
    event.stopPropagation()
  })

  $('#search_query_string').on('keydown', (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      return false
    }
  })

  $('.blob_attachments').attr('target', '_blank')

  generateUrl = (url, params, form) => {
    let additional_params = jQuery.param(params)
    return url + form + '&' + additional_params
  }

  $('.date-picker').daterangepicker({
    timePicker: true,
    timePicker24Hour: true,
    singleDatePicker: true,
    showDropdowns: true,
    locale: { format: DATE_FORMAT.DD_MM_YYYY_HH_MM }
  })

  $('.date-picker-with-clear').daterangepicker({
    timePicker: true,
    timePicker24Hour: true,
    singleDatePicker: true,
    showDropdowns: true,
    autoUpdateInput: false,
    locale: {
      cancelLabel: 'Clear',
      format: DATE_FORMAT.DD_MM_YYYY_HH_MM
    }
  })

  $('.date-range-picker-with-clear').daterangepicker({
    timePicker: true,
    timePicker24Hour: true,
    autoUpdateInput: false,
    showDropdowns: true,
    timeZone: TIME_ZONE,
    locale: {
      format: DATE_FORMAT.DD_MM_YYYY_HH_MM,
      cancelLabel: 'Clear'
    }
  })

  $('.date-picker-with-clear').on('apply.daterangepicker', (event, picker) => {
    $('.date-picker-with-clear').val(picker.startDate.format(DATE_FORMAT.DD_MM_YYYY_HH_MM))
  })

  $('.date-range-picker-with-clear').on(
    'apply.daterangepicker',
    (event, picker) => {
      $('.date-range-picker-with-clear').val(
        picker.startDate.format(DATE_FORMAT.DD_MM_YYYY_HH_MM) +
          ' ~ ' +
          picker.endDate.format(DATE_FORMAT.DD_MM_YYYY_HH_MM)
      )
    }
  )

  $('.date-picker-with-clear, .date-range-picker-with-clear').on(
    'cancel.daterangepicker',
    () => {
      $('.date-picker-with-clear, .date-range-picker-with-clear').val('')
    }
  )
})
